import 'ant-design-vue/dist/reset.css';
import {
  Pagination,
  ConfigProvider,
  Cascader,
  Select,
  Input,
  Modal,
  Popconfirm,
  Tooltip,
  Button,
  Drawer,
  Form,
  Checkbox,
  Row,
  Col,
  Switch,
  Spin
} from "ant-design-vue";


export default function install(app) {
  app.use(Pagination)
    .use(ConfigProvider)
    .use(Cascader)
    .use(Select)
    .use(Input)
    .use(Modal)
    .use(Popconfirm)
    .use(Tooltip)
    .use(Button)
    .use(Drawer)
    .use(Form)
    .use(Checkbox)
    .use(Row)
    .use(Col)
    .use(Switch)
    .use(Spin);
}