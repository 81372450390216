import { getToken, redirectLogin } from "@/utils/token";
import axios from "axios";

export const instance = axios.create({
  baseURL: "/gongwei/",
  timeout: 20 * 1000, // 将请求超时时间设置为 5 秒
});
const token = getToken()
if (token) {
  instance.defaults.headers.common.Authorization = token
}

instance.interceptors.response.use(
  response => {
    // 在此实现响应统一处理逻辑
    if (response.data.code === 401) {
      redirectLogin(response.data.data)
      return
    } else {
      return response.data;
    }
  },
  error => {
    return Promise.reject(error);
  }
);
