<template>
  <a-config-provider
    :locale="locale"
    :theme="{
      token: {
        colorPrimary: 'rgba(255, 90, 0, 0.8);',
      },
    }"
  >
    <!-- <layouts /> -->
    <router-view v-slot="{ Component, route }">
      <keep-alive>
        <component
          :is="Component"
          :key="route.meta.usePathKey ? route.path : undefined"
        />
      </keep-alive>
    </router-view>
  </a-config-provider>
</template>
<script>
// import Layouts from "@/layouts/user";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { useStore } from "vuex";
import { computed, watch } from "vue";
export default {
  name: "App",
  // components: {
  //   Layouts
  // },
  data() {
    return {
      locale: zhCN
    };
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.state.user);
    store.dispatch("getUser");
    watch(user, val => {
      if (!val) {
        store.dispatch("getUser");
      }
    });
    return {
      user
    };
  }
};
</script>

<style>
:root {
  --fontColor: #ff5a00;
  --backgroundColor: #ff5a00;
  --backgroundHoverColor: rgba(255, 90, 0, 0.1);
  --backgroundDisableColor: initial;
  --borderColor: initial;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  font-size: 14px;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  background: #eeeeee;
  text-align: left;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.88);
  display: flex;
  flex-direction: column;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC,
    Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
    sans-serif;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb
/* *:hover::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb:hover  */
 {
  background-color: rgb(202, 199, 199);
  width: 8px;
  height: 8px;
  border-radius: 5px;
}

@font-face {
  font-family: "iconfont"; /* Project id 2808607 */
  src: url("//at.alicdn.com/t/font_2808607_7fcvhllo65n.woff2?t=1631869577081")
      format("woff2"),
    url("//at.alicdn.com/t/font_2808607_7fcvhllo65n.woff?t=1631869577081")
      format("woff"),
    url("//at.alicdn.com/t/font_2808607_7fcvhllo65n.ttf?t=1631869577081")
      format("truetype");
}
</style>
