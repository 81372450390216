import { createStore } from "vuex";
import { getUserInfo } from "@/api/user";
const store = createStore({
  state() {
    return {
      user: null,
      auth: []
    };
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setAuthority(state, auth) {
      // console.log(state.user)
      state.auth = auth
    }
  },
  actions: {
    getUser({ commit }) {
      getUserInfo().then(response => {
        const { userInfo,btns } = response.data;
        commit("setUser", userInfo);
        commit("setAuthority", btns);
      });
    }
  }
});

export default store;
