import { createRouter, createWebHistory } from "vue-router";
import { instance as http } from "@/vendor/axios.js";
import { setToken } from "@/utils/token";
const routes = [
  {
    path: "/",
    component: () => import("@/layouts/user"),
    children: [
      {
        path: "/",
        name: "MyGongwei",
        component: () => import("@/views/myGongwei")
      }
    ]
  },
  {
    path: "/admin",
    component: () => import("@/layouts/admin"),
    children: [
      {
        path: "todo-list",
        name: "TodoList",
        component: () => import("@/views/admin/todo-list")
      },
      {
        path: "done-list",
        name: "DoneList",
        component: () => import("@/views/admin/done-list")
      },
      {
        path: "gongwei-list",
        name: "GongweiList",
        component: () => import("@/views/admin/gongwei-list")
      },
      {
        path: "warning",
        name: "GongweiWarning",
        component: () => import("@/views/admin/gongwei-warning")
      },
      {
        path: "gongwei-report",
        name: "GongweiReport",
        redirect: { name: "All" },
        component: () => import("@/views/admin/gongwei-report/index.vue"),
        children: [
          {
            path: "all",
            name: "All",
            component: () => import("@/views/admin/gongwei-report/all.vue")
          },
          {
            path: "department",
            name: "Department",
            component: () =>
              import("@/views/admin/gongwei-report/department.vue")
          },
          {
            path: "floor",
            name: "Floor",
            component: () => import("@/views/admin/gongwei-report/floor.vue")
          },
          {
            path: "hrd",
            name: "Hrd",
            component: () => import("@/views/admin/gongwei-report/hrd.vue")
          },
          {
            path: "unbound",
            name: "Unbound",
            component: () => import("@/views/admin/gongwei-report/unbound.vue")
          },
          {
            path: 'admin',
            name: 'Admin',
            component: () => import('@/views/admin/gongwei-report/admin.vue')
          }
        ]
      },
      {
        path: 'config',
        children: [
          {
            path: 'city',
            name: 'City',
            component: () => import('@/views/admin/config/city.vue')
          },
          {
            path: 'building',
            name: 'Building',
            component: () => import('@/views/admin/config/building.vue')
          },
          {
            path: 'floor',
            nam: 'Floor',
            component: () => import('@/views/admin/config/floor.vue')
          },
          {
            path: 'section',
            name: 'section',
            component: () => import('@/views/admin/config/section.vue')
          },
          {
            path: 'subAdmin',
            name: 'SubAdmin',
            component: () => import('@/views/admin/config/subAdmin.vue')
          }
        ]
      },
      {
        path: 'auth',
        name: 'Auth',
        children: [
          {
            path: 'user',
            name: 'User',
            component: () => import('@/views/admin/auth/user.vue')
          },
          {
            path: 'role',
            name: 'Role',
            component: () => import('@/views/admin/auth/role.vue')
          }
        ]
      }
      // {
      //   path: '/admin/unbound-report',
      //   name: 'UnboundReport',
      //   component: () => import ('@/views/admin/unbound-personnel/index.vue')
      // }
    ]
  }
];

const base = process.env.VUE_APP_BASE_URL;
const router = createRouter({
  history: createWebHistory(base),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.query.authtoken) {
    setToken(to.query.authtoken)
    http.defaults.headers.common.Authorization = to.query.authtoken;
    delete to.query.authtoken
    return next(to)
  }
  next();
});

export default router;
