const key = 'auth-token-gongwei'

export function getToken () {
  return window.localStorage.getItem(key)
}

export function setToken (token) {
  window.localStorage.setItem(key, token)
}

export function redirectLogin (idassUrl) {
  const url = new URL(idassUrl)
  url.searchParams.append('redirect_url', window.location.href)
  window.stop()
  window.location.replace(url.href)
}