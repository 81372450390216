import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/vendor/i18n";
import installAntDesign from "@/vendor/ant-design";
import '@/assets/styles/color.less'


const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(installAntDesign)

app.mount("#app");
